import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  mijnVakken = [];
  gebruikersNaam: string;

  constructor( private fs: AngularFirestore, private auth: AngularFireAuth, private router: Router) { }

  ngOnInit(): void {
    this.auth.idTokenResult.subscribe( data => {
      if (!data){
        this.router.navigateByUrl('');
      } else {
        this.gebruikersNaam = data.claims.name;
        this.getData(data.claims.user_id);
      }
    });
  }

  getData(uid: string){
    this.fs.collection('Klassen', ref => ref.where('studenten', 'array-contains', uid)).valueChanges().subscribe( data => {
      this.mijnVakken = data;
    });
  }

  openLink(url: string){
    window.open(url,"_tab");
  }

}
