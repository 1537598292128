<mat-form-field appearance="outline">
    <mat-label>Zoek op naam, studentenNr, email</mat-label>
    <input [(ngModel)]="searchValue" (keyup)="search()" type="text" matInput>
    <mat-icon matSuffix>search</mat-icon>
</mat-form-field>

<div class="students">
    <table>
        <thead>
            <td>Naam</td>
            <td>StudenNr</td>
            <td>Email</td>
            <td></td>
        </thead>
        <tbody>
            <tr *ngFor="let student of studenten">
                <td>{{student?.naam}}</td>
                <td>{{student?.studentenNr}}</td>
                <td>{{student?.email}}</td>
                <td (click)="addStudentToKlas(student)" class="button-col"><button mat-icon-button color="primary"><mat-icon>add</mat-icon></button></td>
            </tr>
        </tbody>
    </table>
</div>
