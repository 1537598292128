import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  flipOut: boolean = false;
  loginForm: FormGroup;
  authSubscription: Subscription;

  constructor(private router: Router, private auth: AngularFireAuth, private snackbar: MatSnackBar) {
    this.loginForm = new FormGroup({
      userName: new FormControl("", [Validators.required, Validators.email]),
      passWord: new FormControl("", [Validators.required])
    });
   }

  go(){
    if (this.loginForm.valid){
      this.auth.signInWithEmailAndPassword(this.loginForm.controls.userName.value, this.loginForm.controls.passWord.value).then( data => {
        if (data){
          this.auth.idTokenResult.subscribe( data2 => {
            if (data2.claims.docent){
              this.flipOut = true;
              setTimeout(()=>{    
                this.router.navigateByUrl("/docent-home");
              }, 2000);
            } else if (data2.claims.student && !data2.claims.docent){
              this.flipOut = true;
              setTimeout(()=>{    
                this.router.navigateByUrl("/student-home");
              }, 2000);
            }
          });
        }
      }).catch( error => {
        if (error.code == "auth/user-not-found"){
          this.snackbar.open(`Ongeldig emailadres`,'', {duration: 4000, panelClass: 'error', verticalPosition: 'top'});
        } else if (error.code == "auth/wrong-password"){
          this.snackbar.open(`Ongeldig wachtwoord`,'', {duration: 4000, panelClass: 'error', verticalPosition: 'top'});
        } else {
          this.snackbar.open(`Woopsie poopsy: ${error.message}`,'', {duration: 4000, panelClass: 'error', verticalPosition: 'top'});
        }
      });
    } else {
      this.snackbar.open(`De velden zijn niet correct ingevuld.`,'', {duration: 4000, panelClass: "error",  verticalPosition: 'top'});
    }
  }

  ngOnInit(): void {
    this.authSubscription = this.auth.idTokenResult.subscribe( data => {
      if (data){
        if (data.claims.docent){
          this.router.navigateByUrl('/docent-home')
        } else if (data.claims.student && !data.claims.docent) {
          this.router.navigateByUrl('/student-home')
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

}
