import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-newtestdialog',
  templateUrl: './newtestdialog.component.html',
  styleUrls: ['./newtestdialog.component.scss']
})
export class NewtestdialogComponent implements OnInit {

  TestFormGroup: FormGroup;
  constructor(private fs: AngularFirestore, 
    @Inject(MAT_DIALOG_DATA) public data, 
    private auth: AngularFireAuth,
    private snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<NewtestdialogComponent>) { 
    this.TestFormGroup = new FormGroup({
      naam: new FormControl("", [Validators.required, Validators.minLength(5)])
    });
  }

  ngOnInit(): void {
    if (this.data.edit){
      this.TestFormGroup.controls.naam.setValue(this.data.testToEdit.toetsNaam);
    }
  }

  addTest(){
    if (this.TestFormGroup.valid){
      if (this.data.edit){
        this.auth.currentUser.then( user => {
          let editedOn = new Date();
          
          let object = {
            toetsNaam: this.TestFormGroup.controls.naam.value,
            editedOn: editedOn,
            editedBy: {
              naam: user.displayName,
              uid: user.uid
            }
          };

          let toetsRef = this.fs.collection('Toetsen').doc(this.data.testToEdit.toetsId).ref;
          toetsRef.update(object).then( res => {
            this.snackbar.open(`Succesvol toetsnaam gewijzigd.`, '', {
              panelClass: 'success-snackbar',
              duration: 7000
            }).afterOpened().subscribe( data => {
              this.dialogRef.close();
            });
          }).catch( err => {
            this.snackbar.open(`${err.message}`, "", {
              panelClass: 'error',
              duration: 7000
            });
          });
        });
      } else {
        this.auth.currentUser.then( user => {
          let newUid = this.fs.createId();
          let createdOn = new Date();
          
          let object = {
            toetsId: newUid,
            toetsNaam: this.TestFormGroup.controls.naam.value,
            vakId: this.data.vak.id,
            vakNaam: this.data.vak.naam,
            createdOn: createdOn,
            createdBy: {
              naam: user.displayName,
              uid: user.uid
            }
          };

          this.fs.collection('Toetsen').doc(newUid).set(object).then( res => {
            this.snackbar.open(`Succesvol toets toegevoegd aan ${this.data.vak.naam}.`, '', {
              panelClass: 'success-snackbar',
              duration: 7000
            }).afterOpened().subscribe( data => {
              this.dialogRef.close();
            });
          }).catch( err => {
            this.snackbar.open(`${err.message}`, "", {
              panelClass: 'error',
              duration: 7000
            });
          });
        });
      }
    } else {
      this.snackbar.open("Toetsnaam ongeldig", "", {
        panelClass: 'error',
        duration: 5000
      });
    }
  }

}
