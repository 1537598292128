import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { VakkendetailService } from '../../services/vakkendetail.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AddclassComponent } from 'src/app/admin/addclass/addclass.component';

@Component({
  selector: 'app-dashboard-docent',
  templateUrl: './dashboard-docent.component.html',
  styleUrls: ['./dashboard-docent.component.scss']
})
export class DashboardDocentComponent implements OnInit {

  vakken: any;
  initialVakken: any;
  searchBar: String;
  gebruikersNaam: string;
  isAdmin: boolean;
  constructor( 
    private fs: AngularFirestore, 
    private router: Router, 
    private vakkenService: VakkendetailService,
    private auth: AngularFireAuth,
    private dialog: MatDialog
    ) { }

  ngOnInit(): void {
    this.auth.idTokenResult.subscribe( data => {
      if (data?.claims.admin){
        this.isAdmin = true;
        this.gebruikersNaam = data.claims.name;
        this.getAllVakken(data.claims.user_id);
      }else if (data?.claims.docent){
        this.gebruikersNaam = data.claims.name;
        this.getVakken(data.claims.user_id);
      }
    });
  }

  filterVak() {
    this.vakken = this.initialVakken.filter((vak: any) => vak?.naam.toLowerCase().includes(this.searchBar.toLowerCase()));
  };

  goToDetail(vak: any){
    this.vakkenService.selectedVak = vak;
    this.router.navigateByUrl(`docent-home/details/${vak.id}`);
  }

  getVakken(user?: any){
    this.fs.collection('Klassen', ref => ref.where('docenten', 'array-contains', user)).valueChanges()
    .subscribe(data => {
      this.initialVakken = data;
      this.vakken = data;
    });
  }

  editClass(vak: any){
    let ref = this.dialog.open(AddclassComponent, {panelClass: 'add-class-panel'});
    ref.componentInstance.existingKlas = true;
    ref.componentInstance.prefilledKlas = vak;
  }

  getAllVakken(user? : any){
    this.fs.collection('Klassen').valueChanges()
    .subscribe(data => {
      this.initialVakken = data;
      this.vakken = data;
    });
  }

  openLink(url: string){
    window.open(url);
  }

  openClassDialog(){
    this.router.navigateByUrl(`docent-home/add-class`);
    // this.dialog.open(AddclassComponent);
  }

}
