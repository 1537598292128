<mat-drawer-container class="drawer-container">
    <mat-drawer disableClose="true" #drawer class="sidenav" mode="over" position="end">
        <button (click)="closeSideNav()" mat-icon-button><mat-icon>close</mat-icon></button>
        <app-aanwezigheid-dialog [inputSideNav]="drawer" *ngIf="open"></app-aanwezigheid-dialog>
        <app-addtestresults [inputSideNav]="drawer" *ngIf="vakService.toetsDrawer"></app-addtestresults>
    </mat-drawer>

    <div class="body">
        <button routerLink="/docent-home" class="back-button" mat-flat-button>
            <mat-icon>arrow_back</mat-icon>
            Ga terug
        </button>

        <mat-tab-group disablePagination="true" mat-align-tabs="center" [selectedIndex]="0">
            <mat-tab label="Basis">
                <div class="row-1">
                    <div class="info-container">
                        <div class="links">
                            <h1>{{selectedVak?.naam}}</h1>
                            <p class="literatuur-header">Link naar literatuur:</p>
                            <p class="literatuur">
                                <a [href]="selectedVak?.literatuur">{{selectedVak?.literatuur}}</a>
                            </p>
                            <p class="literatuur-header">
                                Link naar teams-meeting:
                            </p>
                            <p class="teams-link">
                                <a [href]="selectedVak?.teams">{{selectedVak?.teams}}</a>
                            </p>
                        </div>
                        <button (click)="editVak()" mat-icon-button><mat-icon>edit</mat-icon></button>
                    </div>
                    <div class="aanwezigheid-div">
                        <button color="primary" (click)="toggleNav()" mat-raised-button>Voeg aanwezigheid toe +</button>
                        <table class="aanwezigheid-list">
                            <thead>
                                <th>Datum</th>
                                <th class="edit-col"></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of aanwezigheid">
                                    <td> {{x.datum?.toDate() | date:'dd-M-YYYY ---- HH:mm'}} </td>
                                    <td class="button-col"><button color="primary" mat-icon-button (click)="deleteAanwezigheid(x.lesId)"><mat-icon>delete</mat-icon></button></td>
                                    <td class="button-col"><button color="primary" mat-icon-button (click)="editLes(x.lesId)"><mat-icon>edit</mat-icon></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            
                <div class="studenten-table">
                    <div class="box">
                        <h1>Studenten:</h1>
                        <button (click)="addStudent()" mat-raised-button>Voeg student toe +</button>
                    </div>
                    <mat-form-field appearance="outline">
                        <mat-label>Zoek op naam, studentenNr, email</mat-label>
                        <input [(ngModel)]="searchValue" (keyup)="search()" type="text" matInput>
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    
                    <div class="students">
                        <table>
                            <thead>
                                <td></td>
                                <td>Naam</td>
                                <td>StudenNr</td>
                                <td class="email-col">Email</td>
                                <td></td>
                                <td></td>
                            </thead>
                            <tbody>
                                <tr *ngFor="let student of studenten; let i = index">
                                    <td class="number-col">{{i + 1}}.</td>
                                    <td>{{student?.naam}}</td>
                                    <td>{{student?.studentenNr}}</td>
                                    <td class="email-col">{{student?.email}}</td>
                                    <td><button (click)="getInfoFromStudents(student)" mat-icon-button color="primary"><mat-icon>info</mat-icon></button></td>
                                    <td (click)="deleteStudentFromKlas(student)" class="button-col"><button mat-icon-button color="primary"><mat-icon>delete</mat-icon></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </mat-tab>

            <!-- Toetsentab -->
            <mat-tab label="Toetsen">
                <div class="toets-tab-body">
                    <div class="buttons-div">
                        <button (click)="newTest(false)" class="new-test-button" color="primary" mat-raised-button>Voeg nieuwe toets toe aan vak</button>
                    </div>
                    <h1>Toetsen</h1>
                    <table>
                        <thead>
                            <th>Naam</th>
                            <th>Aangemaakt op</th>
                            <th class="desktop-col">Aangemaakt door</th>
                            <th class="desktop-col">Laatst gewijzigd op</th>
                            <th class="desktop-col">Laatst gewijzigd door</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let t of toetsen; let i = index;" [ngClass]="{'grey' : i%2 == 0, 'animate__animated animate__fadeIn': true}">
                                <td> <a (click)="openTestResultDrawer(t)">{{t.toetsNaam}}</a> </td>
                                <td>{{t.createdOn.toDate() | date:'dd-MM-YYYY - HH:mm'}}</td>
                                <td class="desktop-col">{{t.createdBy.naam}}</td>
                                <td class="desktop-col">{{t.editedOn == undefined ? 'nvt' : t.editedOn.toDate() | date:'dd-MM-YYYY - HH:mm'}}</td>
                                <td class="desktop-col">{{t.editedBy == undefined ? 'nvt' : t.editedBy.naam}}</td>
                                <td class="edit-col">
                                    <button (click)="newTest(true, t)" mat-icon-button><mat-icon>edit</mat-icon></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mat-tab>

        </mat-tab-group>
    </div>

  </mat-drawer-container>
  <router-outlet></router-outlet>

