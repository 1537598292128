<mat-toolbar>
    <button (click)="goHome()" mat-icon-button><mat-icon>menu</mat-icon></button>
    <div (click)="logOut()" class="log-out-button">
        <p>Log uit</p>
        <mat-icon>logout</mat-icon>
    </div>
</mat-toolbar>
<mat-sidenav-container class="example-container">
    <mat-sidenav #menu class="side-nav" mode="side" [opened]="isDesktop">
        <div routerLink="/docent-home" class="side-nav-item">
            <mat-icon>dashboard</mat-icon>
            <div>Dashboard</div>
        </div>
        <div class="divider"></div>
        <div routerLink="/student-home" *ngIf="isAlsoStudent"  class="side-nav-item">
            <mat-icon>person</mat-icon>
            <div>Naar studentenomgeving</div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content class="content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
