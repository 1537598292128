import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { FormControl } from '@angular/forms';
import { VakkendetailService } from '../../services/vakkendetail.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-aanwezigheid-dialog',
  templateUrl: './aanwezigheid-dialog.component.html',
  styleUrls: ['./aanwezigheid-dialog.component.scss']
})
export class AanwezigheidDialogComponent implements OnInit {

  @Input() inputSideNav: MatSidenav;
  studenten: any;
  datePicker = new FormControl(new Date());
  saveButton = false;

  constructor(private fs: AngularFirestore, private vakService: VakkendetailService, private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.datePicker = new FormControl(new Date());
    if (this.vakService.edit){
      this.getAanwezigheidEntries();
    } else {
      this.getStudents();
    }
  }

  getStudents(){
    this.fs.collection('Studenten', ref => ref.where("vakken", "array-contains",this.vakService.selectedVak.id).orderBy('naam','asc')).valueChanges().subscribe( data => {
      this.studenten = data;
    });
  }
  
  getAanwezigheidEntries(){
    this.fs.collection('AanwezigheidsInvoer', ref => ref.where('lesId','==',this.vakService.editLes).orderBy('naam','asc')).valueChanges()
    .subscribe( data => {
      this.studenten = data;
      this.datePicker.setValue(data[0]['datum'].toDate())
    });
  }

  async save(){
    this.saveButton = true;
    let lesId = this.fs.createId();
    let addedEntries = 0;

    if (this.vakService.edit){
      for (let x of this.studenten){
        let res = await this.fs.collection('AanwezigheidsInvoer').doc(x.id).update({
          datum: this.datePicker.value,
          presentie: x.presentie
        })
        .catch( error => {
          this.snackbar.open(`Aanwezigheid kon niet worden aangepast voor ${x.naam}`,'',{duration: 7000, panelClass: 'error'})
        });
      }
      this.inputSideNav.close();
      this.snackbar.open('Succesvol aanwezigheid aangepast.','',{panelClass: 'success-snackbar', duration: 5000})
    } else {
      for (let x of this.studenten){
        if (x.presentie && x.presentie.length > 2){
          let docId = this.fs.createId();
          addedEntries ++;
          let aanwezigheidsEntry = {
            id: docId,
            lesId: lesId,
            datum: this.datePicker.value,
            vakId: this.vakService.selectedVak.id,
            vakNaam: this.vakService.selectedVak.naam,
            studentId: x.uid,
            studentenNr: x.studentenNr,
            naam: x.naam,
            presentie: x.presentie
          }
          await this.fs.collection('AanwezigheidsInvoer').doc(docId).set(aanwezigheidsEntry).catch( error => {
            this.snackbar.open(`Iets ging verkeerd! Stuur melding door naar IT: ${error.message}`,'',{duration: 10000, panelClass: 'error'});
          });
        }
      }
      this.inputSideNav.close();
      this.snackbar.open(`Presentie toegevoegd van ${addedEntries} studenten.`, '', {duration: 5000, panelClass: 'success-snackbar'});
    }

    this.saveButton = false;
  }



}
