<div class="body">
    <div class="resultaten-container">
        <p *ngIf="!toetsResultaten">Hier komen uw behaalde resultaten te staan.</p>
        <!-- header -->
        <h1 *ngIf="toetsResultaten?.length >= 1">Uw toetsresultaten</h1>
        <div class="filter-div">
            <div [ngClass]="{'active': selectedVak == 'all'}" (click)="selectVak('all')" class="chip">Alle vakken</div>
            <div *ngFor="let vak of vakken" [ngClass]="{'active': selectedVak == vak.id}" (click)="selectVak(vak.id)" class="chip">{{vak.naam}}</div>
        </div>
        <div class="view-container">
            <div *ngFor="let r of toetsResultaten" [ngClass]="{'hidden': selectedVak != r.vakId && selectedVak != 'all'}" class="cijfer-view">
                <div class="toets-div">
                    <div class="cursus-div">{{r.vakNaam}}</div>
                    {{r.toetsNaam}}
                    <button (click)="openDetails(r)" mat-icon-button><mat-icon>info</mat-icon></button>
                </div>
                <div [ngClass]="{'red': r.toetsResultaat < 6 || r.toetsResultaat == 'o' || r.toetsResultaat == 'O', 'green': true}" class="cijfer">{{r.toetsResultaat}}</div>
            </div>
        </div>
    </div>
</div>