<div [formGroup]="EditVakForm" class="dialog-body">
    <p>
        <mat-form-field appearance="legacy">
          <mat-label>Naam:</mat-label>
          <input matInput formControlName="naam">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="legacy">
          <mat-label>Link naar literatuur:</mat-label>
          <input matInput formControlName="literatuurLink">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="legacy">
          <mat-label>Link naar Teams-vergadering:</mat-label>
          <input matInput formControlName="teamsLink">
        </mat-form-field>
      </p>
      <div class="button-div">
          <button (click)="cancel()" class="cancel-button" mat-raised-button>Annuleer</button>
          <button (click)="save()" class="save-button" color="primary" mat-raised-button>Sla op</button>
      </div>
</div>