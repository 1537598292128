<h1>Toetsdetails:</h1>
<table>
<tbody>
    <tr class="tint">
        <td class="left">Vak:</td>
        <td class="right">{{data.vakNaam}}</td>
    </tr>
    <tr>
        <td class="left">Toets:</td>
        <td class="right">{{data.toetsNaam}}</td>
    </tr>
    <tr class="tint">
        <td class="left">Ingevoerd op:</td>
        <td class="right">{{data.createdOn.toDate() | date:'dd-MM-YYYY'}}</td>
    </tr>
    <tr>
        <td class="left">Ingevoerd door:</td>
        <td class="right">{{data.createdBy.naam}}</td>
    </tr>
    <tr class="tint">
        <td class="left">Toetsresultaat:</td>
        <td class="right">{{data.toetsResultaat}}</td>
    </tr>
    <tr>
        <td class="left">Toetsdatum:</td>
        <td class="right">{{data?.toetsDatum?.toDate() | date:'dd-MM-YYYY'}}</td>
    </tr>
</tbody>
</table>
