import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-home-student',
  templateUrl: './home-student.component.html',
  styleUrls: ['./home-student.component.scss']
})
export class HomeStudentComponent implements OnInit {

  selectedTab: number;
  @ViewChild("menu") sideNav: MatSidenav;
  isDesktop = false;
  isAlsoDocent: boolean = false;

  constructor(private router: Router, private auth: AngularFireAuth, private snackbar: MatSnackBar) { }

  goHome(){
    this.sideNav.toggle();
  }

  setSelectedTab(index: number){
    this.selectedTab = index;
    if (window.innerWidth < 600){
      this.sideNav.close();
    }
  }

  logOut(){
    this.auth.signOut();
  }

  checkIfAlsoDocent(){
    this.auth.idTokenResult.subscribe(data =>{
      if (data){
        this.isAlsoDocent = data.claims?.docent;
      }
    });
  }

  ngOnInit(): void {
    this.checkIfAlsoDocent();
    this.auth.idTokenResult.subscribe( data => {
      if (!data){
        this.snackbar.open("U bent niet ingelogd. Log aub opnieuw in.", "", {duration: 5000})
      }
      
      else if (!data.claims.student){
        this.snackbar.open("U bent niet bevoegd om deze pagina te zien.", "", {duration: 5000})
        this.router.navigateByUrl('');
      }

      if (window.innerWidth > 600){
        this.isDesktop = true;
      } else {
        this.isDesktop = false;
      }
    });
  }

}
