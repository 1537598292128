import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DeletedialogComponent } from '../../docent/deletedialog/deletedialog.component';

@Component({
  selector: 'app-addclass',
  templateUrl: './addclass.component.html',
  styleUrls: ['./addclass.component.scss']
})
export class AddclassComponent implements OnInit {

  existingKlas: boolean;
  prefilledKlas: any;

  initialDocenten = [];
  docenten = [];
  afbeeldingen = [];

  addKlasForm: FormGroup = new FormGroup({
    naam: new FormControl('', [Validators.required, Validators.minLength(5)]),
    teams: new FormControl(''),
    literatuur: new FormControl(''),
    actief: new FormControl(false),
    afbeelding: new FormControl('', [Validators.required]),
    docenten: new FormControl([],{})
  });

  constructor(
    private fs: AngularFirestore,    
    private auth: AngularFireAuth,
    private router: Router,
    private snackBar: MatSnackBar,
    private matDialogRef: MatDialogRef<AddclassComponent>,
    private dialog: MatDialog
    ) { }

  ngOnInit(): void {
    this.auth.idTokenResult.subscribe( data => {
      if (data?.claims.admin){

        this.getAfbeeldingen();

        if (this.prefilledKlas && this.existingKlas){
          this.addKlasForm.patchValue({
            naam: this.prefilledKlas.naam,
            afbeelding: this.prefilledKlas.afbeelding,
            teams: this.prefilledKlas.teams,
            literatuur: this.prefilledKlas.literatuur,
            actief: this.prefilledKlas.actief,
            docenten: this.prefilledKlas.docenten
          });
        } 

        this.getDocenten();

      } else {
        this.router.navigate(['./'])
      }
    });
  }

  isSelectedDocent(docentId: string) : boolean {
    return this.addKlasForm.controls?.docenten?.value?.filter((docent) => docent === docentId).length === 1;
  }

  getDocenten(){
    this.fs.collection('Docenten').valueChanges()
    .subscribe(data => {
      if (this.existingKlas && this.prefilledKlas){
        this.initialDocenten = data;
        this.docenten = data;
      } else {
        this.initialDocenten = data.filter((d) => d['actief']);
        this.docenten = data.filter((d) => d['actief']);
      }
    })
  }

  getAfbeeldingen(){
    this.fs.collection('Afbeeldingen').valueChanges()
    .subscribe(data => {
      this.afbeeldingen = data.map((d) => d['afbeelding']);
    })
  }

  updateDocentenLijst(event : MatCheckboxChange){
    let tempArray: string[] = this.addKlasForm.controls.docenten.value;

    event.checked ? 
    tempArray.push(event.source.value) :
    tempArray = tempArray.filter((docent) => docent != event.source.value);

    this.addKlasForm.controls.docenten.setValue(tempArray)
  }

  selectAfbeelding(afbeelding: string){
    this.addKlasForm.controls.afbeelding.setValue(afbeelding);
  }

  submitEdit(){
    if (this.addKlasForm.valid && this.existingKlas){

        this.fs.collection('Klassen').doc(this.prefilledKlas.id)
        .update({...this.addKlasForm.value})

        this.snackBar.open("Klas toegevoegd!",null,{
          duration: 1000
        }).afterOpened().subscribe( a => {
          this.addKlasForm.reset();
          this.matDialogRef.close();
        });
      }
    else {
      this.snackBar.open("Er ging iets fout. Heeft u alle velden ingevuld?",null,{
        duration: 2000,
      });
    }
  }

  deleteKlas(){
    if (this.existingKlas && this.prefilledKlas){
      let dialogRef = this.dialog.open(DeletedialogComponent, {
        minWidth: '200px',
        maxWidth: '400px',
        data: {
          isKlas : true,
        }
      });

      dialogRef.afterClosed().subscribe( async (data) => {
        if (data){
          try {
            const del = await this.fs.collection('Klassen').doc(this.prefilledKlas.id).delete();
            this.snackBar.open('Klas succesvol verwijderd');
            this.matDialogRef.close();
          } catch (err) {
            this.snackBar.open('Er ging iets fout. Neem contact op met een administrator.');
          }
        }
      });
    }
  }

  submitForm(){
    if (this.existingKlas && this.prefilledKlas){
      this.submitEdit();
    } 

    else if (this.addKlasForm.valid) {
          let id = this.fs.createId();

          this.fs.collection('Klassen').doc(id).set({
            id: id,
            ...this.addKlasForm.value
          }).then((res) => {
            this.snackBar.open("Klas toegevoegd!",null,{duration: 1000})
            .afterOpened().subscribe( a => {
              this.addKlasForm.reset();
              if (!this.existingKlas && !this.prefilledKlas) {
                setTimeout(() => {
                  this.docenten = [...this.initialDocenten];
                }, 500)
              } else {
                this.matDialogRef.close();
              }
            });
          });
    }
      else {
        this.snackBar.open("Er ging iets fout. Zijn alle verplichte velden ingevuld?",null,{
          duration: 2000,
        });
      }
  }
}
