import { HomeStudentComponent } from './student/home-student/home-student.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeDocentComponent } from './docent/home-docent/home-docent.component';
import { VakkenDetailComponent } from './docent/vakken-detail/vakken-detail.component';
import { DashboardDocentComponent } from './docent/dashboard-docent/dashboard-docent.component';
import { CijfersPlusAbsentieComponent } from './docent/cijfers-plus-absentie/cijfers-plus-absentie.component';
import { AddclassComponent } from './admin/addclass/addclass.component';

const routes: Routes = [
  {path: "", component: LoginComponent},
  {path: "student-home", component: HomeStudentComponent},
  {path: "docent-home", component: HomeDocentComponent, children: [
    {path: '', component: DashboardDocentComponent},
    {path: 'details/:id', component: VakkenDetailComponent, children: [
    ]},
    {path: 'student-info/:studentid/:vakid', component: CijfersPlusAbsentieComponent },
    {path: 'add-class', component: AddclassComponent}
  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
