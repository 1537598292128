<div [formGroup]="TestFormGroup">
    <h2>Voeg een nieuwe toets toe aan {{data.vak.naam}}</h2>
    <mat-form-field appearance="legacy">
        <input formControlName="naam" matInput required/>
        <mat-hint>Naam van de toets moet minimaal 5 karakters lang zijn.</mat-hint>
      </mat-form-field>
      <mat-dialog-actions align="end">
        <button color="primary" mat-button mat-dialog-close>Annuleer</button>
        <button [disabled]="TestFormGroup.invalid" (click)="addTest()" color="primary" mat-button cdkFocusInitial>{{ data.edit ? 'Wijzig' : 'Voeg toe'}}</button>
      </mat-dialog-actions>
</div>
