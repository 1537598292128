import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { ToetsdetailsComponent } from './../toetsdetails/toetsdetails.component';

@Component({
  selector: 'app-resultaten',
  templateUrl: './resultaten.component.html',
  styleUrls: ['./resultaten.component.scss']
})
export class ResultatenComponent implements OnInit {

  constructor(private fs: AngularFirestore, private auth: AngularFireAuth, private dialog: MatDialog) { }

  toetsResultaten: any;
  selectedVak: string;
  vakken: any;

  ngOnInit(): void {
    this.getMyResults();
    this.selectedVak = 'all';
  }

  openDetails(r: any){
    let ref = this.dialog.open(ToetsdetailsComponent,{
      data: r,
      minWidth: '250px',
      maxWidth: '500px',
      minHeight: '300px'
    });
  }

  selectVak(vak: any){
    this.selectedVak = vak;
  }

  async getMyResults(){
    this.auth.user.subscribe( data => {
      this.fs.collection('Toetsresultaten', ref => ref.where('studentId','==',data?.uid)).valueChanges()
      .subscribe( data2 => {
        let tempArray = data2;
        let idsVanVakken = Array.from(new Set(data2.map(item => item['vakId'])));
        this.fs.collection('Klassen', ref => ref.where('id','in',idsVanVakken).where('studenten','array-contains',data?.uid)).valueChanges()
        .subscribe(data3 => {
          this.vakken = data3;
        });
        for (let x of tempArray){
          let val = parseFloat(x['toetsResultaat'].replace(',','.'));
          if (!isNaN(val)){
            x['toetsResultaat'] = val;
          }
        }
        this.toetsResultaten = tempArray;
      });
    });
  }

}
