import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ToetsdetailsComponent } from './../../student/toetsdetails/toetsdetails.component';

@Component({
  selector: 'app-cijfers-plus-absentie',
  templateUrl: './cijfers-plus-absentie.component.html',
  styleUrls: ['./cijfers-plus-absentie.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CijfersPlusAbsentieComponent implements OnInit {

  toetsResultaten: any;
  selectedVak: string;
  selectedStudent: string;
  studentNaam: string;
  studentNr: string;
  vakNaam: string;
  aanwezigheidEntries: any[];
  aanwezigheidPercentage: number;
  chartData: any[];

  constructor(private fs: AngularFirestore, private dialog: MatDialog, private route: ActivatedRoute, private router: Router) { 
  }

  ngOnInit(): void {
    this.route.params.subscribe( params => {
      if (params){
        this.selectedVak = params['vakid'];
        this.selectedStudent = params['studentid'];

        this.getStudent();
        this.getVak();
        this.getAanwezigheidEntries();
        this.getTestResults();
      }
    });
  }

  goBack(){
    this.router.navigateByUrl(`docent-home/details/${this.selectedVak}`);
  }

  openDetails(r: any){
    let ref = this.dialog.open(ToetsdetailsComponent,{
      data: r,
      minWidth: '250px',
      maxWidth: '500px',
      minHeight: '300px'
    });
  }

  async getVak(){
    this.fs.collection('Klassen').doc(this.selectedVak).valueChanges()
    .subscribe( data => {
      this.vakNaam = data['naam'];
    });
  }

  async getStudent(){
    this.fs.collection('Studenten').doc(this.selectedStudent).valueChanges()
    .subscribe( data => {
      this.studentNaam = data['naam'];
      this.studentNr = data['studentenNr'];
    });
  }

  async getTestResults(){
      this.fs.collection('Toetsresultaten', ref => ref.where('studentId','==',this.selectedStudent).where('vakId','==', this.selectedVak)).valueChanges()
      .subscribe( data2 => {
        if (data2){
          let tempArray = data2;
          for (let x of tempArray){
            let val = parseFloat(x['toetsResultaat'].replace(',','.'));
            if (!isNaN(val)){
              x['toetsResultaat'] = val;
            }
          }
          this.toetsResultaten = tempArray;
        }
      });
  }

  getAanwezigheidEntries(){
      this.fs.collection('AanwezigheidsInvoer', ref => ref.where('studentId','==',this.selectedStudent).where('vakId','==',this.selectedVak).orderBy('datum','desc')).valueChanges()
      .subscribe( entries => {
        this.aanwezigheidEntries = entries;
        let totalEntries = entries.length;
        let countEntries = 0;
        let afgemeld = 0;
        let afwezig = 0;
        for (let x of entries){
          if (x['presentie'] == 'aanwezig'){
            countEntries ++;
          }
          else if (x['presentie'] == 'afgemeld'){
            afgemeld++;
          }
          else if (x['presentie'] == 'afwezig'){
            afwezig++;
          }
        }
        this.chartData = [
          {
            "name" : "Aanwezig",
            "value" : countEntries
          },
          {
            "name" : "Afgemeld",
            "value" : afgemeld
          },
          {
            "name" : "Afwezig",
            "value" : afwezig
          }
        ];
        this.aanwezigheidPercentage = countEntries / totalEntries * 100;
      });
  }

}
