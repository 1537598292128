import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { VakkendetailService } from '../../services/vakkendetail.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-editvak',
  templateUrl: './editvak.component.html',
  styleUrls: ['./editvak.component.scss']
})
export class EditvakComponent implements OnInit {

  EditVakForm: FormGroup;
  constructor(private vakService: VakkendetailService, 
    private dialogRef: MatDialogRef<EditvakComponent>,
    private fs: AngularFirestore,
    private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.EditVakForm = new FormGroup({
      naam: new FormControl(""),
      literatuurLink: new FormControl(""),
      teamsLink: new FormControl("")
    });

    this.EditVakForm.controls.naam.setValue(this.vakService.selectedVak.naam);
    this.EditVakForm.controls.literatuurLink.setValue(this.vakService.selectedVak.literatuur);
    this.EditVakForm.controls.teamsLink.setValue(this.vakService.selectedVak.teams);
  }

  cancel(){
    this.dialogRef.close();
  }

  save(){
    // Write save code here.
    let docRef = this.fs.collection("Klassen").doc(this.vakService.selectedVak.id);

    docRef.set({
      naam: this.EditVakForm.controls.naam.value,
      literatuur: this.EditVakForm.controls.literatuurLink.value,
      teams: this.EditVakForm.controls.teamsLink.value
    }, {merge: true}).then( confirmed => {
      this.snackbar.open("Succesvol gewijzigd!", "", {
        duration: 5000,
        panelClass: 'success-snackbar'
      })
    })

    this.dialogRef.close();
  }



}
