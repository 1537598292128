import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-aanwezigheid',
  templateUrl: './aanwezigheid.component.html',
  styleUrls: ['./aanwezigheid.component.scss']
})
export class AanwezigheidComponent implements OnInit {

  aanwezigheidEntries: any;
  constructor(private fs: AngularFirestore, private auth: AngularFireAuth) { }

  ngOnInit(): void {
    this.getAanwezigheidEntries();
  }

  getAanwezigheidEntries(){
    this.auth.user.subscribe( data => {
      this.fs.collection('AanwezigheidsInvoer', ref => ref.where('studentId','==',data.uid).orderBy('datum','desc')).valueChanges()
      .subscribe( entries => {
        this.aanwezigheidEntries = entries;
      })
    });
  }

}
