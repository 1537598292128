<div class="body-div">

    <button (click)="goBack()" color="primary" mat-raised-button>Terug naar vak</button>

    <div class="data-div">
        <div class="stats">
            <h1 class="vak"><span style="color: goldenrod">Vak:</span> {{vakNaam}} </h1>
            <h2><span style="color: goldenrod;">Student:</span> {{studentNaam}}</h2>
            <h2><span style="color: goldenrod;">Studentnummer:</span> {{studentNr}}</h2>
            <h2><span style="color: goldenrod;">Aanwezigheid:</span> {{aanwezigheidPercentage | number: '1.1-1'}} % </h2>
        </div>
        <ngx-charts-pie-chart
        [view]="[600,400]"
        [scheme]="{domain: ['#5AA454', '#C7B42C','#A10A28']}"
        [results]="chartData"
        [gradient]="true"
        [legend]="true"
        [legendPosition]="'right'"
        [labels]="true"
        [doughnut]="false">
        </ngx-charts-pie-chart>
    </div>

    <div class="panels-parent">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Cijfers
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="resultaten-container">
                <p *ngIf="!toetsResultaten">Hier komen de behaalde resultaten te staan.</p>
                <div class="view-container">
                    <div *ngFor="let r of toetsResultaten" class="cijfer-view">
                        <div class="toets-div">
                            <!-- <div class="cursus-div">{{r.vakNaam}}</div> -->
                            {{r.toetsNaam}}
                            <button (click)="openDetails(r)" mat-icon-button><mat-icon>info</mat-icon></button>
                        </div>
                        <div [ngClass]="{'red': r.toetsResultaat < 6 || r.toetsResultaat == 'o' || r.toetsResultaat == 'O', 'green': true}" class="cijfer">{{r.toetsResultaat}}</div>
                    </div>
                </div>
            </div>

          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Aanwezigheid
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="aanwezigheid-container">
                <!-- legenda -->
                <div class="aanwezigheid-legenda row">
                    <div class="legenda-item">
                        <div class="legenda-kleur green"></div>
                        <div class="legenda-text">Aanwezig</div>
                    </div>
                    <div class="legenda-item">
                        <div class="legenda-kleur red"></div>
                        <div class="legenda-text">Afwezig</div>
                    </div>
                    <div class="legenda-item">
                        <div class="legenda-kleur orange"></div>
                        <div class="legenda-text">Afgemeld</div>
                    </div>
                </div>
            
                <!-- aanwezigheid tabel -->
                <table *ngIf="aanwezigheidEntries">
                    <thead>
                        <th>Vak</th>
                        <th>Datum</th>
                        <th class="presentie-col">Presentie</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let a of aanwezigheidEntries">
                            <td> {{a.vakNaam}} </td>
                            <td> {{a.datum?.toDate() | date:'dd-MM-YYYY'}} </td>
                            <td class="presentie-col"> 
                                <div class="legenda-kleur" 
                                [ngClass]="{'red' : a.presentie == 'afwezig', 'orange': a.presentie == 'afgemeld', 'green': a.presentie == 'aanwezig'}"></div>     
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </mat-expansion-panel>
    </div>
</div>