import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AngularFirestore } from '@angular/fire/firestore';
import { VakkendetailService } from '../../services/vakkendetail.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { EdittestresultComponent } from '../edittestresult/edittestresult.component';

@Component({
  selector: 'app-addtestresults',
  templateUrl: './addtestresults.component.html',
  styleUrls: ['./addtestresults.component.scss']
})
export class AddtestresultsComponent implements OnInit {

  @Input() inputSideNav: MatSidenav;
  @Input() drawer: boolean;
  datePicker = new FormControl();
  individualTests: String;
  studenten = [];
  toetsResultaten = [];
  eersteResultaten = [];
  constructor(private fs: AngularFirestore, 
    private dialog: MatDialog,
    public vakService: VakkendetailService, 
    private auth: AngularFireAuth,
    private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.getTestResults();
  }

  editResultaat(toetsResultaat: any){
    let dialogRef = this.dialog.open(EdittestresultComponent, {
      data: toetsResultaat
    });
  }

  async getTestResults(){
    this.eersteResultaten = [];
    this.toetsResultaten = [];
    await this.fs.collection('Toetsresultaten', 
    ref => ref.where("toetsId","==",this.vakService.selectedToets.toetsId))
    .get().toPromise().then( data => {
      let tempArray = [];
      data.forEach(doc => {
        this.eersteResultaten.push(doc.data());
        tempArray.push(doc.data());
      });
      this.toetsResultaten = tempArray.sort( (a,b) => ( a.naam < b.naam ? -1 : 1));
      if (this.toetsResultaten.length >= 1){
        if (this.toetsResultaten[0]['toetsDatum']) {
          this.datePicker.setValue(this.toetsResultaten[0]['toetsDatum'].toDate())
        }
      }
      this.getStudents(this.toetsResultaten);
    });
  }

  getStudents(secondArray: any){
    let arraywithIds = [];
    for (let x of secondArray){
      arraywithIds.push(x.studentId);
    }
    this.fs.collection('Studenten', ref => ref.where("vakken", "array-contains",this.vakService.selectedVak.id).orderBy('naam','asc')).valueChanges().subscribe( data => {
      this.studenten = [];
      if (secondArray?.length >= 1){
        this.studenten = data.filter( el => {
          return !arraywithIds.includes(el['uid']);
        });
      } else {
        this.studenten = data;
      }

      if (this.studenten.length >= 1){
        if (this.studenten[0]['toetsDatum']) {
          this.datePicker.setValue(this.studenten[0]['toetsDatum'].toDate())
        }
      }
  });
}

  async save(){
    let user = await this.auth.currentUser;
    const resToAdd = this.studenten.filter(function(el) {
      return el.toetsResultaat?.replace(/\s/g, "").length >= 1;
    });

    for (let x of resToAdd){
        let uid = this.fs.createId();
        let createdDate = new Date();
        let editedString = x.toetsResultaat.replace('.',",");
        let obj = {
          id: uid,
          toetsId: this.vakService.selectedToets.toetsId,
          toetsNaam: this.vakService.selectedToets.toetsNaam,
          vakId: this.vakService.selectedVak.id,
          vakNaam: this.vakService.selectedVak.naam,
          createdOn: createdDate,
          createdBy: {naam: user.displayName, id: user.uid},
          studentId: x.uid,
          naam: x.naam,
          studentenNr: x.studentenNr,
          toetsResultaat: editedString,
          toetsDatum: this.datePicker.value
        }
        await this.fs.collection('Toetsresultaten').doc(uid).set(obj);
      }

    if (this.eersteResultaten != this.toetsResultaten){
      const myArrayFiltered = this.toetsResultaten.filter( el => {
        return this.eersteResultaten.some( f => {
          return f.toetsResultaat !== el.toetsResultaat || el?.toetsDatum !== null && f?.toetsDatum != this.datePicker.value;
        });
      });
      
      if (myArrayFiltered.length >= 1){
        for (let x of myArrayFiltered){
          let editDate = new Date();
          await this.fs.collection('Toetsresultaten').doc(x.id).update({
            toetsResultaat: x.toetsResultaat.replace('.',","),
            editedOn: editDate,
            editedBy: {naam: user.displayName, id: user.uid},
            toetsDatum: this.datePicker.value
          }).catch( err => {
            this.snackbar.open(`Error bij het aanpassen van toetsresultaat van ${x.naam}`,'', {
              duration: 7000,
              panelClass: 'error'
            });
          });
        }
      }

      this.snackbar.open("Wijzigingen opgeslagen", "", {
        duration: 5000,
        panelClass: 'success-snackbar'
      }).afterOpened().subscribe(d => {
        this.vakService.toetsDrawer = false;        
        this.inputSideNav.close();
      });
      
    }
  }

}
