import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-home-docent',
  templateUrl: './home-docent.component.html',
  styleUrls: ['./home-docent.component.scss']
})
export class HomeDocentComponent implements OnInit {

  selectedTab: number;
  vakken: any;
  isAlsoStudent: boolean;
  @ViewChild("menu") sideNav: MatSidenav;
  isDesktop: boolean;

  constructor(private router: Router, private auth: AngularFireAuth, private snackbar: MatSnackBar) { }

  logOut(){
    this.auth.signOut();
  }

  goHome(){
    this.sideNav.toggle();
  }
  
  checkIfAlsoStudent(){
    this.auth.idTokenResult.subscribe(data =>{
      if (data){
        this.isAlsoStudent = data.claims?.student;
      }
    });
  }

  ngOnInit(): void {
    this.auth.idTokenResult.subscribe( data => {
      setTimeout(() => {
        if (!data){
          this.snackbar.open("U bent niet ingelogd. Log aub opnieuw in.", "", {duration: 5000});
          this.router.navigateByUrl('');
        }

        else if (!data.claims.docent){
          this.snackbar.open("U bent niet bevoegd om deze pagina te zien.", "", {duration: 5000})
          this.router.navigateByUrl('');
        }

        else {
          this.checkIfAlsoStudent();
        }
      }, 1000)
    });

    if (window.innerWidth > 1000){
      this.isDesktop = true;
    } else {
      this.isDesktop = false;
    }
  }

}
