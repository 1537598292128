<div class="home-student">
    <mat-toolbar>
        <button (click)="goHome()" mat-icon-button><mat-icon>menu</mat-icon></button>
        <div (click)="logOut()" class="log-out-button">
            <p>Log uit</p>
            <mat-icon>logout</mat-icon>
        </div>
    </mat-toolbar>
    <mat-sidenav-container class="example-container">
        <mat-sidenav #menu class="side-nav" mode="side" [opened]="isDesktop">
            <div (click)="setSelectedTab(0)" class="side-nav-item">
                <mat-icon>dashboard</mat-icon>
                <div>Dashboard</div>
            </div>
            <div class="divider"></div>
            <div (click)="setSelectedTab(1)"  class="side-nav-item">
                <mat-icon>bar_chart</mat-icon>
                <div>Mijn resultaten</div>
            </div>
            <div class="divider"></div>
            <div  (click)="setSelectedTab(2)" class="side-nav-item">
                <mat-icon>how_to_reg</mat-icon>
                <div>Mijn aanwezigheid</div>
            </div>
            <div class="divider"></div>
            <div routerLink="/docent-home" *ngIf="isAlsoDocent"  class="side-nav-item">
                <mat-icon>person</mat-icon>
                <div>Naar docentenomgeving</div>
            </div>
            <!-- <div class="divider"></div> -->
        </mat-sidenav>
        <mat-sidenav-content>
            <mat-tab-group [selectedIndex]="selectedTab" mat-align-tabs="start">
                    <mat-tab>
                        <app-dashboard></app-dashboard>
                    </mat-tab>
                    <mat-tab>
                        <app-resultaten></app-resultaten>
                    </mat-tab>
                    <mat-tab>
                        <app-aanwezigheid></app-aanwezigheid>
                    </mat-tab>
            </mat-tab-group>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>