<h1>{{vakService.selectedToets.toetsNaam}}</h1>

<label>Zijn de toetsen op dezelfde dag afgenomen?</label>
<mat-radio-group [(ngModel)]="individualTests" class="radio-group" color="primary">
    <mat-radio-button value="true">Ja</mat-radio-button>
    <mat-radio-button value="false">Nee (Aparte datum per persoon)</mat-radio-button>
</mat-radio-group>

<mat-form-field *ngIf="individualTests == 'true'" class="form-field" appearance="fill">
    <mat-label>Datum van toets</mat-label>
    <input matInput [formControl]="datePicker" [matDatepicker]="picker">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<table>
    <thead>
        <th>Naam</th>
        <th>StudentNummer</th>
        <th>{{individualTests == 'true' ? 'Toetsdatum' : ''}}</th>
        <th>Toetsresultaat</th>
        <th></th>
    </thead>
    <tbody>
        <tr *ngFor="let student of toetsResultaten; let i = index;" [ngClass]="{'tint': true}">
            <td>{{student.naam}}</td>
            <td>{{student.studentenNr}}</td>
            <td>
                {{student.toetsDatum?.toDate() | date:'dd-M-YYYY'}}
                <!-- <input type="date" [(ngModel)]="student.toetsDatum" [value]="student.toetsDatum?.toDate()"/> -->
            </td>
            <td class="buttons-col">
                <input [(ngModel)]="student.toetsResultaat" required/>
            </td>
            <td class="edit-col">
                <mat-icon (click)="editResultaat(student)" color="primary">edit</mat-icon>
            </td>
        </tr>
        <tr *ngFor="let student of studenten; let i = index;" [ngClass]="{'tint': false}">
            <td>{{student.naam}}</td>
            <td>{{student.studentenNr}}</td>
            <td *ngIf="individualTests == 'false'">
                <input type="date" [(ngModel)]="student.toetsDatum"/>
            </td>
            <td class="buttons-col">
                <input [(ngModel)]="student.toetsResultaat" required />
            </td>
        </tr>
    </tbody>
</table>
<button (click)="save()" color="primary" class="save-button" mat-raised-button>
    Opslaan
    <mat-icon>save</mat-icon>
</button>