<div class="body container">
    <h1 class="animate__animated animate__zoomInDown" *ngIf="gebruikersNaam">Welkom {{gebruikersNaam}}!</h1>
    <!-- Left container for the study-subjects -->
    <div class="vakken-container">
        <div *ngFor="let vak of mijnVakken" class="vak-block animate__animated animate__zoomInDown">
            <img [src]="vak.afbeelding" alt="">
            <div class="mat-title-block">{{vak.naam}}</div>
            <div class="mat-button-block">
                <button (click)="openLink(vak.literatuur)" mat-icon-button>
                    <mat-icon>menu_book</mat-icon>
                </button>
                <div class="vertical-divider"></div>
                <button (click)="openLink(vak.teams)" class="gotoclass-button" mat-flat-button> Naar les
                    <mat-icon>east</mat-icon>
                </button>
            </div>
        </div>
        <!-- <div class="vak-block">
            <img src="../../assets/quran.jpeg" alt="">
            <div class="mat-title-block">Tafsīr Al-Qur'ān Al-Kareem</div>
            <div class="mat-button-block">
                <button mat-icon-button>
                    <mat-icon>menu_book</mat-icon>
                </button>
                <div class="vertical-divider"></div>
                <button class="gotoclass-button" mat-flat-button> Naar les
                    <mat-icon>east</mat-icon>
                </button>
            </div>
        </div>
        <div class="vak-block">
            <img src="../../assets/arabic.jpeg" alt="">
            <div class="mat-title-block">Dars-e-Nizāmī</div>
            <div class="mat-button-block">
                <button mat-icon-button>
                    <mat-icon>menu_book</mat-icon>
                </button>
                <div class="vertical-divider"></div>
                <button class="gotoclass-button" mat-flat-button> Naar les
                    <mat-icon>east</mat-icon>
                </button>
            </div>
        </div> -->
    </div>
</div>