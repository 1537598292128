<div class="aanwezigheid-container">
    <!-- legenda -->
    <div class="aanwezigheid-legenda row">
        <div class="legenda-item">
            <div class="legenda-kleur green"></div>
            <div class="legenda-text">Aanwezig</div>
        </div>
        <div class="legenda-item">
            <div class="legenda-kleur red"></div>
            <div class="legenda-text">Afwezig</div>
        </div>
        <div class="legenda-item">
            <div class="legenda-kleur orange"></div>
            <div class="legenda-text">Afgemeld</div>
        </div>
    </div>

    <!-- aanwezigheid tabel -->
    <table>
        <thead>
            <th>Vak</th>
            <th>Datum</th>
            <th class="presentie-col">Presentie</th>
        </thead>
        <tbody>
            <tr *ngFor="let a of aanwezigheidEntries">
                <td> {{a.vakNaam}} </td>
                <td> {{a.datum.toDate() | date:'dd-MM-YYYY'}} </td>
                <td class="presentie-col"> 
                    <div class="legenda-kleur" 
                    [ngClass]="{'red' : a.presentie == 'afwezig', 'orange': a.presentie == 'afgemeld', 'green': a.presentie == 'aanwezig'}"></div>     
                </td>
            </tr>
        </tbody>
    </table>
</div>