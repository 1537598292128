import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeStudentComponent } from './student/home-student/home-student.component';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ResultatenComponent } from './student/resultaten/resultaten.component';
import { DashboardComponent } from './student/dashboard/dashboard.component';
import { AanwezigheidComponent } from './student/aanwezigheid/aanwezigheid.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule} from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeDocentComponent } from './docent/home-docent/home-docent.component';
import { DashboardDocentComponent } from './docent/dashboard-docent/dashboard-docent.component';
import { VakkenDetailComponent } from './docent/vakken-detail/vakken-detail.component';
import { EditvakComponent } from './docent/editvak/editvak.component';
import { AddstudentComponent } from './docent/addstudent/addstudent.component';
import { AanwezigheidDialogComponent } from './docent/aanwezigheid-dialog/aanwezigheid-dialog.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { DeletedialogComponent } from './docent/deletedialog/deletedialog.component';
import { NewtestdialogComponent } from './docent/newtestdialog/newtestdialog.component';
import { AddtestresultsComponent } from './docent/addtestresults/addtestresults.component';
import { ToetsdetailsComponent } from './student/toetsdetails/toetsdetails.component';
import { EdittestresultComponent } from './docent/edittestresult/edittestresult.component';
import { CijfersPlusAbsentieComponent } from './docent/cijfers-plus-absentie/cijfers-plus-absentie.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AddclassComponent } from './admin/addclass/addclass.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeStudentComponent,
    ResultatenComponent,
    DashboardComponent,
    AanwezigheidComponent,
    HomeDocentComponent,
    DashboardDocentComponent,
    VakkenDetailComponent,
    EditvakComponent,
    AddstudentComponent,
    AanwezigheidDialogComponent,
    DeletedialogComponent,
    NewtestdialogComponent,
    AddtestresultsComponent,
    ToetsdetailsComponent,
    EdittestresultComponent,
    CijfersPlusAbsentieComponent,
    AddclassComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    
    //Angular Material
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatTabsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    
    //Angularfire
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule
  ],
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'nl-NL'}, {provide: MatDialogRef, useValue: {}} ],
  bootstrap: [AppComponent]
})
export class AppModule { }
