<mat-form-field class="form-field" appearance="fill">
    <mat-label>Datum van les</mat-label>
    <input matInput [formControl]="datePicker" [matDatepicker]="picker">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

<table>
    <thead>
        <th>Naam</th>
        <th>StudentNummer</th>
        <th>Aanwezigheid</th>
    </thead>
    <tbody>
        <tr *ngFor="let student of studenten; let i = index;" [ngClass]="{'tint': i%2 == 0}">
            <td>{{student.naam}}</td>
            <td>{{student.studentenNr}}</td>
            <td class="buttons-col">
                <!-- aanwezig knop -->
                <div #tooltip="matTooltip"
                matTooltip="Student is aanwezig"
                matTooltipPosition="above"
                matTooltipHideDelay="100" 
                (click)="student.presentie = 'aanwezig'" class="indicator aanwezig" 
                [ngClass]="{'active': student.presentie == 'aanwezig'}"></div>
                
                <!-- afwezig knop -->
                <div #tooltip="matTooltip"
                matTooltip="Student afwezig"
                matTooltipPosition="above"
                matTooltipHideDelay="100" 
                (click)="student.presentie = 'afwezig'" 
                class="indicator afwezig" 
                [ngClass]="{'active': student.presentie == 'afwezig'}"></div>

                <!-- afgemeld knop -->
                <div #tooltip="matTooltip"
                matTooltip="Student heeft zich afgemeld"
                matTooltipPosition="above"
                matTooltipHideDelay="100"
                (click)="student.presentie = 'afgemeld'" 
                class="indicator afgemeld" 
                [ngClass]="{'active': student.presentie == 'afgemeld'}"></div>
            </td>
        </tr>
    </tbody>
</table>
<button [disabled]="saveButton" (click)="save()" color="primary" class="save-button" mat-raised-button>
    Opslaan
<mat-icon>save</mat-icon>
</button>
