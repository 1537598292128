import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';

@Component({
  selector: 'app-edittestresult',
  templateUrl: './edittestresult.component.html',
  styleUrls: ['./edittestresult.component.scss']
})
export class EdittestresultComponent implements OnInit {

  editForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<EdittestresultComponent>, private fs: AngularFirestore) { 
    this.editForm = new FormGroup({
      toetsResultaat: new FormControl(data.toetsResultaat, [Validators.required]),
      toetsDatum: new FormControl(data.toetsDatum.toDate(), [Validators.required]),
      redenWijziging: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
  }

  wijzig(){
    let gewijzigdObject = {
      toetsResultaat: this.editForm.controls.toetsResultaat.value,
      toetsDatum: this.editForm.controls.toetsDatum.value,
      redenWijziging: this.editForm.controls.redenWijziging.value,
      geschiedenis: firebase.firestore.FieldValue.arrayUnion({
        dataAlvorensWijziging: {
          eerdereResultaat: this.data.toetsResultaat,
          eerdereDatum: this.data.toetsDatum
        },
        wijzigDatum: new Date()
      })
    }
  }

}
