<div [formGroup]="addKlasForm" class="add-class-form">
    <button *ngIf="!existingKlas" routerLink="../" class="back-btn" mat-flat-button> 
        <mat-icon>west</mat-icon>
        Terug
    </button>
    <mat-form-field appearance="fill">
        <mat-label>Klasnaam</mat-label>
        <input formControlName="naam" matInput type="text"/>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Link naar teams-meeting</mat-label>
        <input formControlName="teams" matInput type="text"/>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Link naar literatuur</mat-label>
        <input formControlName="literatuur" matInput type="text"/>
    </mat-form-field>
    <div>
        <h4>Docent(en) van deze klas:</h4>
        <div class="checkbox-container">
            <mat-checkbox 
            *ngFor="let docent of docenten"
            (change)="updateDocentenLijst($event)"
            [value]="docent.id"
            [checked]="isSelectedDocent(docent.id)">
                {{docent.naam}}
            </mat-checkbox>
        </div>
    </div>
    <h4>Afbeelding voor klas</h4>
    <div class="afbeelding-options">
        <img 
        *ngFor="let afbeelding of afbeeldingen" 
        (click)="selectAfbeelding(afbeelding)"
        [class.selected]="afbeelding == this.addKlasForm.controls.afbeelding.value"
        [src]="afbeelding" alt=""/>
    </div>
    <h4 class="class-active-title"> 
        <span>Klas actief </span>  
        <mat-slide-toggle formControlName="actief"></mat-slide-toggle>
    </h4>  
    <button (click)="submitForm()" color="primary" class="submit-class-btn" mat-raised-button>
        Toevoegen
    </button>
    <button *ngIf="existingKlas" (click)="deleteKlas()" color="warn" class="submit-class-btn" mat-raised-button>
        Verwijder klas
        <mat-icon color="white">delete</mat-icon>
    </button>
</div>