import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VakkendetailService {

  selectedVak: any;
  studenten: any;
  edit: boolean = false;
  editLes: string;
  selectedToets: any;
  toetsDrawer: boolean;

  constructor() { }
}
