import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { VakkendetailService } from '../../services/vakkendetail.service';
import firebase from 'firebase/app';
import 'firebase/firestore';


@Component({
  selector: 'app-addstudent',
  templateUrl: './addstudent.component.html',
  styleUrls: ['./addstudent.component.scss']
})
export class AddstudentComponent implements OnInit {

  studenten = [];
  searchValue: string = "";
  gefilterdeStudenten = [];

  constructor(private fs: AngularFirestore, private vakService: VakkendetailService) { }

  ngOnInit(): void {
    this.getStudents();
  }

  search(){
    let value = this.searchValue.toLowerCase();
    this.studenten = this.gefilterdeStudenten.filter(function(res)  {
      return res.email.toString().toLowerCase().includes(value) || res.naam.toString().toLowerCase().includes(value) || res.studentenNr.toString().toLowerCase().toString().includes(value);
    });
  }

  getStudents(){
    this.fs.collection('Studenten', ref => ref.orderBy('naam','asc')).valueChanges().subscribe( data => {
      let tempStudenten = [];
      for (let student of data) {
        let studentVakken = student['vakken'];
        if (!studentVakken.includes(this.vakService.selectedVak.id)){
          tempStudenten.push(student);
        }
      }
      this.gefilterdeStudenten = tempStudenten;
      this.studenten = tempStudenten;
    });
  }

  addStudentToKlas(student: any){
    let studentRef = this.fs.collection('Studenten').doc(student.uid);
    let klasRef = this.fs.collection('Klassen').doc(this.vakService.selectedVak.id);

    studentRef.update({
      vakken: firebase.firestore.FieldValue.arrayUnion(this.vakService.selectedVak.id)
    });

    klasRef.ref.update({
      studenten: firebase.firestore.FieldValue.arrayUnion(student.uid)
    });
  }
}
