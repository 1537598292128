import { DeletedialogComponent } from '../deletedialog/deletedialog.component';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { VakkendetailService } from '../../services/vakkendetail.service';
import { EditvakComponent } from './../editvak/editvak.component';
import { AddstudentComponent } from './../addstudent/addstudent.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewtestdialogComponent } from './../newtestdialog/newtestdialog.component';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'vakken-detail',
  templateUrl: './vakken-detail.component.html',
  styleUrls: ['./vakken-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VakkenDetailComponent implements OnInit {

  selectedVak: any;
  studenten = [];
  gefilterdeStudenten = [];
  toetsen = [];
  searchValue: string = "";
  @ViewChild("drawer") sideNav: MatDrawer;
  open = false;
  aanwezigheid: any;
  testDrawer = false;

  constructor
  (private dialog: MatDialog,
    private route: ActivatedRoute,
    private fs: AngularFirestore,
    public vakService: VakkendetailService,
    private snackbar: MatSnackBar,
    private router: Router,
    private auth: AngularFireAuth
    ) { }

  async ngOnInit() {
    this.route.params.subscribe( params => {
      if (params){
        this.auth.idTokenResult.subscribe( data => {
          this.fs.collection("Klassen").doc(params.id).valueChanges()
          .subscribe( data2 => {
            this.selectedVak = data2;
            this.vakService.selectedVak = this.selectedVak;
            this.getAanwezigheid();
            this.getToetsen();
          });
          this.getStudents();
        });
      }
    });
  }

  getInfoFromStudents(student: any){
    this.router.navigateByUrl(`/docent-home/student-info/${student.uid}/${this.selectedVak.id}`);
  }

  openTestResultDrawer(test: any){
    this.vakService.selectedToets = test;
    this.vakService.toetsDrawer = true;
    this.sideNav.open();
  }

  newTest(isEdit: boolean, testToEdit?: any){
    let dialogRef = this.dialog.open(NewtestdialogComponent, 
      {
      disableClose: true, 
      minWidth: '250px', 
      width: '90%',
      data: { vak: this.selectedVak, edit: isEdit, testToEdit: testToEdit}
    });
  }

  getToetsen(){
    this.fs.collection('Toetsen', ref => ref.where('vakId','==',this.selectedVak.id)).valueChanges().subscribe( data => {
      this.toetsen = data;
    });
  }

  closeSideNav(){
    this.vakService.edit = false;
    this.sideNav.close();
    this.open = this.sideNav.opened;
    this.vakService.toetsDrawer = false;
  }

  toggleNav(){
    this.sideNav.open();
    this.open = this.sideNav.opened;
  }

  editLes(lesId: string){
    this.vakService.edit = true;
    this.vakService.editLes = lesId;
    this.sideNav.open();
    this.open = this.sideNav.opened;
  }

  search(){
    let value = this.searchValue.toLowerCase();
    this.studenten = this.gefilterdeStudenten.filter(function(res){
      return res.email.toString().toLowerCase().includes(value) || res.naam.toString().toLowerCase().includes(value) || res.studentenNr.toString().toLowerCase().includes(value);
    });
  }

  getStudents(){
    this.fs.collection('Studenten', ref => ref.orderBy('naam','asc')).valueChanges().subscribe( data => {
      let tempStudenten = [];
      for (let student of data) {
        let studentVakken = student['vakken'];
        if (studentVakken.includes(this.vakService.selectedVak.id)){
          tempStudenten.push(student);
        }
      }
      this.gefilterdeStudenten = tempStudenten;
      this.studenten = tempStudenten;
      console.log(this.studenten);
    });
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value.lesId) === index;
  }

  getAanwezigheid(){
    this.fs.collection('AanwezigheidsInvoer', ref=> ref.where('vakId', '==',this.vakService.selectedVak.id).orderBy('datum','desc')).valueChanges().subscribe( data => {
      let array = data.filter((v,i,a)=>a.findIndex(t=>(t['lesId'] === v['lesId']))===i);
      this.aanwezigheid = array;
    });
  }

  deleteAanwezigheid(lesId: string){
    let ref = this.dialog.open(DeletedialogComponent,{
      data: lesId
    });

    ref.afterClosed().subscribe( data => {
      if (data){
        this.fs.collection('AanwezigheidsInvoer', ref => ref.where('lesId','==',lesId)).valueChanges().subscribe( data2 => {
          for (let x of data2){
            this.fs.collection('AanwezigheidsInvoer').doc(x['id']).delete();
          }

          this.snackbar.open('Succesvol aanwezigheidsinvoer verwijderd','',{
            duration: 7000,
            panelClass: 'success-snackbar'
          })
        });
      }
    })
  }

  deleteStudentFromKlas(student: any){
    let dialogRef = this.dialog.open(DeletedialogComponent, {
      minWidth: '200px',
      maxWidth: '400px',
      data: student.naam
    });

    dialogRef.afterClosed().subscribe( data => {
      if (data){
        let studentRef = this.fs.collection('Studenten').doc(student.uid);
        let klasRef = this.fs.collection('Klassen').doc(this.vakService.selectedVak.id);

        studentRef.update({
          vakken: firebase.firestore.FieldValue.arrayRemove(this.vakService.selectedVak.id)
        });

        klasRef.ref.update({
          studenten: firebase.firestore.FieldValue.arrayRemove(student.uid)
        }).then( res => {
          this.snackbar.open("Student(e) is succesvol verwijderd uit deze klas.","", {
            duration: 5000
          });
        });
      }
    });
  }

  editVak(){
    let dialogRef = this.dialog.open(EditvakComponent, {
      minWidth: '350px',
      maxWidth: '100%'
    });
  }

  addStudent(){
    let dialogRef = this.dialog.open(AddstudentComponent, {
      minWidth: '350px',
      maxWidth: '100%',
      maxHeight: '80%',
      panelClass: "add-student-dialog"
    });
  }

}
