<div class="body">

    <mat-card [formGroup]="loginForm" [ngClass]="{'animate__flipOutY': flipOut}" class="login-card animate__animated animate__flipInY">
        <img class="logo" src="../../assets/logovector.png" alt="">
        <div class="form-div">
            <mat-form-field appearance="fill">
                <mat-label>Gebruikersnaam</mat-label>
                <input (keyup.enter)="go()" formControlName="userName" type="email" matInput/>
                <mat-error>Incorrecte email</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Wachtwoord</mat-label>
                <input (keyup.enter)="go()" formControlName="passWord" type="password" matInput/>
                <mat-icon matSuffix>lock</mat-icon>
            </mat-form-field>
            <button type="submit" (click)="go()" class="login-button" mat-button>Log in</button>
        </div>
    </mat-card>

</div>