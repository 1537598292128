<h2>Wijzig toetsresultaat van {{data.naam}}</h2>
<div [formGroup]="editForm" class="form-div">
    <mat-form-field>
        <mat-label>Toetsresultaat</mat-label>
        <input formControlName="toetsResultaat" matInput type="text" />
    </mat-form-field>
    <mat-form-field class="form-field" appearance="fill">
        <mat-label>Datum van toets</mat-label>
        <input matInput formControlName="toetsDatum" [matDatepicker]="picker" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Reden van wijziging</mat-label>
        <textarea matInput placeholder="Herkansing.. Foutieve invoer.. Correctie.." formControlName="redenWijziging"></textarea>
    </mat-form-field>
    <button (click)="wijzig()" mat-raised-button color="primary">Wijzig toetsresultaat</button>
</div>