<div class="body">
    <h1 class="animate__animated animate__rollIn" *ngIf="gebruikersNaam">Welkom {{gebruikersNaam}}!</h1>

    <mat-form-field appearance="outline">
        <mat-label>Zoek klas</mat-label>
        <input matInput type="text" (keyup)="filterVak()" [(ngModel)]="searchBar" />
    </mat-form-field>

    <button 
    *ngIf="isAdmin"
    color="primary" (click)="openClassDialog()" class="add-class-btn" mat-raised-button> 
        Klas toevoegen
        <mat-icon>add</mat-icon>
    </button>

    <!-- Left container for the study-subjects -->
    <div class="vakken-container">
        <div *ngFor="let vak of vakken" class="vak-block  animate__animated animate__zoomInDown">
            <button *ngIf="isAdmin" (click)="editClass(vak)" color="primary" class="edit-btn" mat-icon-button>
                <mat-icon>edit</mat-icon>
            </button>
            <img [src]="vak.afbeelding" alt="">
            <div [ngClass]="{'inActive' : !vak.actief}"  class="mat-title-block">{{vak.naam}}</div>
            <div class="mat-button-block">
                <button [ngClass]="{'inActive' : !vak.actief}" (click)="openLink(vak.literatuur)" mat-icon-button>
                    <mat-icon>menu_book</mat-icon>
                </button>
                <div class="vertical-divider"></div>
                <button [ngClass]="{'inActive' : !vak.actief}"  (click)="goToDetail(vak)" class="gotoclass-button" mat-flat-button> Zie info
                    <mat-icon>east</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>